<template>

  <v-container>

    <v-card class="entity-view" v-if="order">
      <v-card-title v-if="order">Print Labels - {{order.Reference}} - {{order.Name}}</v-card-title>

      <v-card-text class="d-flex align-center flex-wrap pb-0">

        <v-row class="align-center pb-5">
          <v-col>
            <Can I="create" on="BookIn">
            <v-btn color="error"
                   class="me-3"
                   @click="Print"
                   :disabled="printed">
              <v-icon size="18"
                      class="me-1">
                {{ icons.mdiPrinter  }}
              </v-icon>
              <span>Print Labels</span>
            </v-btn>
            </Can>
            <v-btn color="secondary"
                   class="me-3"
                   :to="{ name: 'order-view', id: order.Id}">
              <v-icon size="18"
                      class="me-1">
                {{ icons.mdiChevronLeft }}
              </v-icon>
              <span>View Order</span>
            </v-btn>

          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text v-if="serverErrorMessage && serverErrorMessage.length > 0" class="d-flex align-center flex-wrap">
        <v-chip color="error" close @click:close="serverErrorMessage = ''">{{ serverErrorMessage }}</v-chip>
      </v-card-text>

      <v-card-text v-if="serverMessage && serverMessage.length > 0" class="d-flex align-center flex-wrap">
        <v-chip color="info" close @click:close="serverMessage = ''">{{ serverMessage }}</v-chip>
      </v-card-text>

    </v-card>


    <v-container class="pa-0 entity-view">
      <v-row>
        <v-col cols="12" sm="12">
          <v-card class="entity-view mt-6 fill-height">
            <v-card-title>Item(s)</v-card-title>

            <v-simple-table v-if="order">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left pl-5" style="width:32px">
                      #
                    </th>
                    <th class="text-left pl-5" style="width:256px">
                      Dimensions
                    </th>
                    <th class="text-left pl-5">
                      Notes
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in order.Items" :key="item.Id">
                    <td class="text-left pl-5">{{ index + 1 }}</td>
                    <td class="text-left pl-5">{{ item.Length }}x{{ item.Width }}x{{ item.Height }} mm</td>
                    <td class="text-left pl-5">{{ item.Notes }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>

        </v-col>
      </v-row>
    </v-container>

  </v-container>

</template>

<script>
  import store from '@/store'
  import { useRouter } from '@core/utils'
  import { ref, watch, computed, expose, getCurrentInstance } from '@vue/composition-api'
  import * as Display from '@/datahelpers/displayTypes'
  var JSPM = require('jsprintmanager');
  import { Can } from '@casl/vue'

  import {
    mdiPencilOutline,
    mdiPlus,
    mdiViewList,
    mdiCheckboxBlankOutline,
    mdiCheckboxMarkedOutline,
    mdiClose,
    mdiChevronLeft,
    mdiPrinter
  } from '@mdi/js'

  export default {
    components: {
      Can
    },
    setup() {

      const { route, router } = useRouter();
      const vm = getCurrentInstance().proxy

      const loading = ref(false)
      const order = ref(null)
      const printed = ref(false);
      const serverErrorMessage = ref(null);
      const serverMessage = ref(null);

      JSPM.JSPrintManager.auto_reconnect = true;
      JSPM.JSPrintManager.start();
      JSPM.JSPrintManager.WS.onStatusChanged = function () {
        if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
        }
        else if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Closed) {
          console.log('JSPrintManager (JSPM) is not installed or not running! Download JSPM Client App from https://neodynamic.com/downloads/jspm');
        }
        else if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Blocked) {
          console.log('JSPM has blocked this website!');
        }
      }

      const fetchOrder = (id) => {
        serverMessage.value = "";
        vm.loading = true
        vm.$http
          .get(`${store.state.apiUrl}/api/orders/${id}/print`)
          .then(({ data }) => {
            vm.order = data

            if (data.LabelPrinted) {
              serverMessage.value = `Label already printed at ${Display.DateTime(data.LabelPrinted)}`;
            }
          })
          .catch(ex => {
            var e = ex.response.data
            if (e.error) {
              if (e.message) serverErrorMessage.value = e.message
              //if (e.errors) vm.serverErrors = e.errors
            } else {
              console.log(e.error);
            }
          })
          .finally(() => {
            vm.loading = false
          })
      }

      // ok grab the order, if it doesn't have a S10 then it will be created and stored at this point
      fetchOrder(route.value.params.id)

      const Print = () => {
        printed.value = true;

        // get labels from api
        vm.$http
          .post(`${store.state.apiUrl}/api/labels/${order.value.Id}`)
          .then(({ data }) => {
            console.log(data);

            // print each one
            data.forEach(label => {
                printLabel(label);
            });
          })
          .catch(ex => {
            var e = ex.response.data
            if (e.error) {
              if (e.message) serverErrorMessage.value = e.message
            } else {
              console.log(e.error);
            }
          })
      }

      const printLabel = (label) => {
        var printLabel = decodeURIComponent(label);
        // test output, seems to render OK
/*        window.open('http://labelary.com/viewer.html?density=8&quality=grayscale&width=4&height=6&units=inches&index=0&rotation=0&zpl=' + encodeURIComponent(label), "_blank")*/
            var cpj = new JSPM.ClientPrintJob();
            cpj.clientPrinter = new JSPM.DefaultPrinter();
            cpj.printerCommands = printLabel;
            cpj.sendToClient();
      }

      return {
        serverErrorMessage,
        serverMessage,
        Print,
        printed,
        order,
        fetchOrder,
        icons: {
          mdiPencilOutline,
          mdiPlus,
          mdiViewList,
          mdiClose,
          mdiChevronLeft,
          mdiPrinter
        },
      };
    }
  }
</script>

<style lang="scss" scoped>
</style>
